<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="Submit()"
      >
        {{ $t('saveData') }}
      </button>
      <b-row class="match-height">
        <b-col md="6">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('promotionDetails') }}
                </h3>
              </div>
            </div>

            <div class="pl-2 pr-2 pt-1">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Promotion"
                  rules="required"
                >
                  <label for="promotion">{{ $t('promotionName') }} : <span class="text-danger">*</span></label>
                  <b-form-input
                    id="promotion"
                    v-model="Obj.name"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('quantity')"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Promotion Range"
                  rules="required"
                >
                  <label for="promotion-range">{{ $t('promotionDateRange') }} : <span class="text-danger">*</span></label>
                  <flat-pickr
                    id="promotion-range"
                    v-model="Obj.getdate"
                    class="form-control"
                    :state="errors.length > 0 ? false:null"
                    :config="{ mode: 'range'}"
                    placeholder="dd/mm/yyyy - dd/mm/yyyy"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="Description">{{ $t('description') }} :</label>
                <b-form-input
                  id="Description"
                  v-model="Obj.details"
                  type="text"
                  :placeholder="$t('description')"
                />
              </b-form-group>

              <b-form-group>
                <label for="check">{{ $t('usageStatus') }}</label><br>
                <b-form-checkbox
                  v-model="Obj.active"
                  name="check-button"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                />
              </b-form-group>
            </div>

          </div>
        </b-col>

        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('userGroup') }}
                </h3>

                <a
                  href="javascript:void(0)"
                  @click="modalGroup()"
                ><i class="fal fa-plus" /> {{ $t('selectUserGroup') }}</a>
              </div>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fieldsGroup"
                :items="itemsGroup"
                class="mb-2"
                show-empty
              >

                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>
                <!-- <template #cell(checkbox)="data">
                  <b-form-checkbox
                    v-model="data.item.checked"
                    @change="toggleChecked(data.item)"
                  />
                </template> -->
                <template #cell(action)="data">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1 text-primary cursor-pointer"
                    @click="showEdit(itemsList,data.item)"
                  />

                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    @click="removeRowGroup(data.item.id)"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('transportType') }}
                </h3>

                <a
                  href="javascript:void(0)"
                  @click="modalTrans()"
                ><i class="fal fa-plus" /> {{ $t('selectShipping') }}</a>
              </div>
            </div>

            <div class="pl-2 pr-2 row">
              <div class="col-md-7">
                <b-table
                  responsive="sm"
                  :fields="fieldsTrans"
                  :items="itemsTrans"
                  class="mb-2"
                  show-empty
                >

                  <template #empty>
                    <div class="text-center">
                      <img
                        src="@/assets/images/anan-img/empty/empty.png"
                        alt="empty"
                        width="180px"
                      >
                      <p>No Data</p>
                    </div>
                  </template>

                  <template #cell(service)="data">
                    <b-form-input
                      v-model="data.item.service"
                      type="number"
                      class="text-center"
                    />
                  </template>

                  <template #cell(action)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-1 text-primary cursor-pointer"
                      @click="EditRow(data.item.rate,data.index)"
                    />

                    <feather-icon
                      icon="TrashIcon"
                      class="text-danger cursor-pointer"
                      @click="removeRow(data.index)"
                    />
                  </template>
                </b-table>
              </div>

              <div class="col-md-5">
                <b-table
                  responsive="sm"
                  :fields="fields"
                  :items="items"
                  class="mb-2"
                  show-empty
                >

                  <template #empty>
                    <div class="text-center">
                      <img
                        src="@/assets/images/anan-img/empty/empty.png"
                        alt="empty"
                        width="180px"
                      >
                      <p>No Data</p>
                    </div>
                  </template>

                  <template #cell(price)="data">
                    <b-form-input
                      v-model="data.item.price"
                      type="number"
                      class="text-center"
                      @input="ChangePrice(data.item,data.index)"
                    />
                  </template>

                </b-table>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-modal
        ref="my-group"
        :title="$t('selectUserGroup')"
        @ok="CalGroupUser()"
      >
        <b-table
          responsive="sm"
          :fields="fieldsList"
          :items="itemsList"
          :selectable="selectable"
          :checked-rows.sync="selectedRows"
          show-empty
        >

          <template #empty>
            <div class="text-center">
              <img
                src="@/assets/images/anan-img/empty/empty.png"
                alt="empty"
                width="180px"
              >
              <p>No Data</p>
            </div>
          </template>

          <template #cell(checkbox)="data">
            <b-form-checkbox
              v-model="data.item.checked"
              @change="toggleChecked(data.item.checked)"
            />
          </template>
          <template #cell(index)="data">
            {{ currentPage * perPage - perPage + data.index + 1 }}
          </template>

          <template #cell(price)="data">
            <b-form-input
              v-model="data.item.price"
              type="number"
              class="text-center"
            />
          </template>

        </b-table>
      </b-modal>

      <b-modal
        ref="my-trans"
        :title="$t('key-71')"
        @ok="submitShipping()"
      >
        <b-table
          responsive="sm"
          :fields="fieldsTrans2"
          :items="paginatedItems"
          :selectable="selectable"
          :checked-rows.sync="selectedRows"
          show-empty
        >

          <template #empty>
            <div class="text-center">
              <img
                src="@/assets/images/anan-img/empty/empty.png"
                alt="empty"
                width="180px"
              >
              <p>No Data</p>
            </div>
          </template>

          <template #cell(checkbox)="data">
            <b-form-checkbox
              v-model="data.item.checked"
              @change="toggleChecked(data.item.name)"
            />
          </template>
          <template #cell(index)="data">
            {{ currentPage * perPage - perPage + data.index + 1 }}
          </template>

          <template #cell(nameTrans)="data">
            {{ data.item.nameTrans }}
          </template>

          <template #cell(price)="data">
            <b-form-input
              v-model="data.item.price"
              type="number"
              class="text-center"
            />
          </template>

          <template #cell(action)="data">
            <feather-icon
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              @click="removeRow(data.index)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="itemsTrans2.length"
          :per-page="perPage"
          align="center"
        />
      </b-modal>

      <!-- Modal Import only -->
      <b-modal
        id="import-only"
        :title="$t('key-72')"
        size="lg"
        @ok="importSave()"
      >
        <b-table
          responsive="sm"
          :fields="fieldsCar"
          :items="itemsCar"
          class="mb-2"
        >
          <template #cell(rateA)="data">
            <b-form-input
              v-model="data.item.rateA"
              type="number"
            />
          </template>

          <template #cell(rateB)="data">
            <b-form-input
              v-model="data.item.rateB"
              type="number"
            />
          </template>

          <template #cell(rateC)="data">
            <b-form-input
              v-model="data.item.rateC"
              type="number"
            />
          </template>

          <template #cell(rateD)="data">
            <b-form-input
              v-model="data.item.rateD"
              type="number"
            />
          </template>

          <template #cell(rateD1)="data">
            <b-form-input
              v-model="data.item.rateD1"
              type="number"
            />
          </template>

          <template #cell(rateS)="data">
            <b-form-input
              v-model="data.item.rateS"
              type="number"
            />
          </template>
        </b-table>

        <b-table
          responsive="sm"
          :fields="fieldsShip"
          :items="itemsShip"
        >
          <template #cell(rateA)="data">
            <b-form-input
              v-model="data.item.rateA"
              type="number"
            />
          </template>

          <template #cell(rateB)="data">
            <b-form-input
              v-model="data.item.rateB"
              type="number"
            />
          </template>

          <template #cell(rateC)="data">
            <b-form-input
              v-model="data.item.rateC"
              type="number"
            />
          </template>
        </b-table>
      </b-modal>

      <!-- Modal preorder here -->
      <b-modal
        id="preorder-only"
        :title="namearr"
        size="lg"
        @ok="preorderSave()"
      >
        <div class="p-1">
          <div class="row">
            <div class="col-md-12">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ $t('ship') }}
                    </th>
                    <th scope="col">
                      {{ $t('kilo') }}.
                    </th>
                    <th scope="col">
                      {{ $t('cubic') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in car.slice().reverse()"
                    :key="item.id"
                  >
                    <td>{{ item.product_type_name }}</td>

                    <td>
                      <b-form-input
                        v-model="item.product_type_kilo"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.product_type_queue"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ $t('ship') }}
                    </th>
                    <th scope="col">
                      {{ $t('kilo') }}.
                    </th>
                    <th scope="col">
                      {{ $t('cubic') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in ship.slice().reverse()"
                    :key="item.id"
                  >
                    <td>{{ item.product_type_name }}</td>

                    <td>
                      <b-form-input
                        v-model="item.product_type_kilo"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.product_type_queue"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BTable,
  BFormCheckbox,
  BModal,
  BPagination,
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BFormCheckbox,
    BModal,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    flatPickr,
  },
  data() {
    return {
      required,
      promotion: '',
      promotionRange: '',
      description: '',
      check: false,

      selectable: true,
      selectedRows: [],
      selectAll: false,

      // Table

      itemsList: [],

      itemsGroup: [],

      items: [],

      itemsTrans: [],
      itemsCar2: [
        {
          car: 'กิโล.', rateA: '50', rateB: '70', rateC: '15', rateD: '20', rateD1: '200', rateS: '90',
        },
        {
          car: 'คิว.', rateA: '1000', rateB: '1500', rateC: '2000', rateD: '2500', rateD1: '3000', rateS: '3500',
        },

      ],
      itemsShip2: [
        {
          ship: 'กิโล.', rateA: '50', rateB: '70', rateC: '15',
        },
        {
          ship: 'คิว.', rateA: '5500', rateB: '9000', rateC: '19000',
        },
      ],

      itemsTrans2: [
        { nameTrans: 'รับสินค้าเอง' },
        { nameTrans: 'บริษัทจัดส่ง 690 บาท' },
        { nameTrans: 'PL ขนส่ง' },
        { nameTrans: 'ไปรษณีย์ (EMS)' },
        { nameTrans: 'นิ่มซี่เส็ง ภาคเหนือ' },
        { nameTrans: 'นิ่มเอ็กเพลส' },
        { nameTrans: 'Kerry' },
        { nameTrans: 'Flash Express' },
        { nameTrans: 'J&T Express' },
        { nameTrans: 'DHL' },
        { nameTrans: 'Best Express' },
        { nameTrans: 'ขนส่งเอกชนอื่นๆ (ระบุช่องหมายเหตุ)' },
        { nameTrans: 'ส่งจากไทยไปจีน' },
      ],

      ID: this.$route.params.id,
      perPage: 7,
      currentPage: 2,
      subrole: [],
      module_list: [],
      module_use: '',
      group_user_list: [],
      group_use: '',
      group_user_type: 1,
      group_userloading: false,
      category: '',
      Obj: [{
        ID: null,
        name: null,
        getdate: null,
        start_date: null,
        end_date: null,
        details: null,
        active: null,
        serviceFee: 0,
        groupuser: [{}],
        shippingtype: [{}],
        delivery: 0,
        service: 0,
      }],
      editindex: null,
      arr: [],
      car: [],
      ship: [],
      namearr: [],
      idarr: [],
    }
  },
  computed: {

    fieldsList() {
      return [
        { key: 'checkbox', label: '', thStyle: { width: '5%' } },
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '70%' },
        },
        {
          key: 'active_user', label: this.$t('activeUse'), sortable: true, thStyle: { width: '25%' }, thClass: 'text-center',
        },
      ]
    },
    fieldsGroup() {
      return [
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '75%' }, thClass: 'text-center',
        },
        {
          key: 'active_user', label: this.$t('activeUse'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },

    fields() {
      return [
        {
          key: 'min', label: this.$t('minimum'), sortable: false, thStyle: { width: '30%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'old_price', label: this.$t('key-73'), sortable: false, thStyle: { width: '30%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'price', label: this.$t('price'), sortable: false, thStyle: { width: '30%' }, thClass: 'text-center', tdClass: 'text-right',
        },
      ]
    },
    fieldsTrans() {
      return [
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'old_service', label: this.$t('serviceFeeOriginal'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'old_delivery', label: this.$t('shippingFeeOriginal'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'service', label: this.$t('serviceFee'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'delivery', label: this.$t('shippingFee'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    itemsShip() {
      return [
        {
          ship: this.$t('kilo'), rateA: '50', rateB: '70', rateC: '15',
        },
        {
          ship: this.$t('cubic'), rateA: '5500', rateB: '9000', rateC: '19000',
        },
      ]
    },
    itemsCar() {
      return [
        {
          car: this.$t('kilo'), rateA: '50', rateB: '70', rateC: '15', rateD: '20', rateD1: '200', rateS: '90',
        },
        {
          car: this.$t('cubic'), rateA: '1000', rateB: '1500', rateC: '2000', rateD: '2500', rateD1: '3000', rateS: '3500',
        },

      ]
    },

    fieldsShip() {
      return [
        {
          key: 'ship', label: this.$t('ship'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'rateA', label: 'A', thStyle: { width: '30%' }, thClass: 'text-center',
        },
        {
          key: 'rateB', label: 'B', thStyle: { width: '30%' }, thClass: 'text-center',
        },
        {
          key: 'rateC', label: 'C', thStyle: { width: '30%' }, thClass: 'text-center',
        },
      ]
    },
    fieldsCar() {
      return [
        {
          key: 'car', label: this.$t('truck'), thStyle: { width: '10%', align: 'center' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'rateA', label: 'A', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateB', label: 'B', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateC', label: 'C', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateD', label: 'D', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateD1', label: 'D1', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateS', label: 'S', thStyle: { width: '15%' }, thClass: 'text-center',
        },
      ]
    },

    fieldsTrans2() {
      return [
        { key: 'checkbox', label: '' },
        {
          key: 'index', label: this.$t('number'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '85%' }, thClass: 'text-center',
        },
      ]
    },

    /// //////////////////////////////////////
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.perPage
      const endIndex = startIndex + this.perPage
      return this.itemsTrans2.slice(startIndex, endIndex)
    },
  },
  async mounted() {
    this.sub_role = []
    await this.getUserList()
    await this.getShippingList()
    await this.getData()
    await this.getItem()
    // this.GetModuleList()
    // this.GetGroupUserList()
  },
  methods: {
    importSave() {
      const obj = {
        // this car
        rateA: this.itemsCar[0].rateA,
        rateB: this.itemsCar[0].rateB,
        rateC: this.itemsCar[0].rateC,
        rateD: this.itemsCar[0].rateD,
        rateD1: this.itemsCar[0].rateD1,
        rateS: this.itemsCar[0].rateS,
        // this ship
        rateA1: this.itemsShip[0].rateA,
        rateB1: this.itemsShip[0].rateB,
        rateC1: this.itemsShip[0].rateC,
      }
      console.log(obj)
    },
    preorder(item, Type) {
      if (item) {
        const re = item.filter(ele => ele.product_type_class === Type)
        return re
      }
      return []
    },
    showEdit(itemsList, item) {
      this.$bvModal.show('preorder-only')
      // const re = itemsList.filter(ele => ele.name === item.name)
      // const res = []
      if (item) {
        itemsList.forEach(ele => {
          if (ele.name === item.name) {
            this.arr = ele.product_type
            this.namearr = ele.name
            // eslint-disable-next-line no-underscore-dangle
            this.idarr = ele._id
          }

        // const obj = {
        //   product_type: ele.product_type,
        // }
        // res.push(obj)
        })
      }
      this.car = this.arr.filter(ele => ele.product_type_class === 1)
      this.ship = this.arr.filter(ele => ele.product_type_class === 2)
      // this.arr = res
      // console.log(this.arr)
      // this.$bvModal.show('preorder-only')
      // this.arr = itemsList
      // console.log(this.arr)
    },
    async getData() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/promotion/promotion_show', { params })
        this.Obj = res
        this.itemsGroup = res.groupuser
        this.itemsGroup.forEach(elegroup => {
          const index = this.itemsList.findIndex(ele => ele.name === elegroup.name)
          if (index >= 0) {
            this.itemsList[index].checked = true
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    preorderSave() {
      const obj = {
        // eslint-disable-next-line no-underscore-dangle
        ID: this.idarr,
        name: this.namearr,
        product_type: this.arr,
      }
      this.$http
        .post('/groupbyuser/list/storeAndupdate', obj)
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    async getItem() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/promotion/promotion_show', { params })
        this.itemsTrans = res.shippingType
        this.itemsTrans.forEach(elegroup => {
          const index = this.itemsTrans2.findIndex(ele => ele.name === elegroup.name)
          if (index >= 0) {
            this.itemsTrans2[index].checked = true
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getUserList() {
      try {
        const { data: res } = await this.$http.get('/groupbyuser/list/show_data')
        this.itemsList = res
      } catch (e) {
        console.log(e)
      }
    },
    async getShippingList() {
      try {
        const { data: res } = await this.$http.get('/shipping_type/show_all')
        this.itemsTrans2 = res
      } catch (e) {
        console.log(e)
      }
    },
    modalTrans() {
      this.$refs['my-trans'].show()
    },
    modalGroup() {
      this.$refs['my-group'].show()
    },
    addRow() {
      const newRow = {
        minimum: 0,
        price: 0,
      }
      this.items.push(newRow)
    },
    removeRow(index) {
      this.items.splice(index, 1)
    },
    addRowTrans() {
      const newRow = {
        nameShip: '',
        serviceFeeOld: 0,
        ShipCostsOrigin: 0,
        serviceFee: 0,
        ShipCosts: 0,
      }
      this.itemsTrans.push(newRow)
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // const das = this.itemsTrans
          const itemarr = []
          this.itemsTrans.forEach(ele => {
            const obj = {
              id_delivery: ele.id_delivery,
              delivery_source: ele.delivery_source,
              destination: ele.destination,
              name: ele.name,
              old_service: ele.old_service,
              service: ele.service,
              old_delivery: ele.old_delivery,
              delivery: ele.delivery,
              rate: this.items,
            }
            itemarr.push(obj)
          })
          const itemgroup = []
          this.itemsGroup.forEach(ele => {
            const obj = {
              id: ele.id,
              name: ele.name,
              active_user: ele.active_user,
            }
            itemgroup.push(obj)
          })
          this.setData()
          const Objnew = {
            ID: this.ID,
            name: this.Obj.name,
            getdate: this.Obj.getdate,
            start_date: this.Obj.start_date,
            end_date: this.Obj.end_date,
            details: this.Obj.details,
            active: this.Obj.active,
            groupuser: itemgroup,
            shippingType: itemarr,
          }
          this.$http.post('/promotion/promotion_update', Objnew)
            .then(() => {
              this.Success(this.$t('key-307'))
              this.$router.push({ name: 'admin-promotion' })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    ChangePrice(item, index) {
      if (index === 0) {
        this.itemsTrans[this.editindex].delivery = item.price
      }
    },
    CalModule() {
      const moduleArr = this.module_use.sub_role.split(',')
      this.subrole.forEach(item => {
        if (moduleArr.includes(item.level.toString())) {
          this.$set(item, 'status', true)
        } else {
          this.$set(item, 'status', false)
        }
      })
    },
    CalGroupUser() {
      const Checked = this.itemsList.filter(item => item.checked)
      this.itemsGroup = Checked
    },
    removeRowGroup() {
      if (this.itemsGroup.splice(this.itemsList, 1)) {
        this.itemsList.forEach(ele => {
          if (this.itemsGroup.findIndex(item => item.id === ele.id)) {
            this.$set(ele, 'checked', false)
          }
        })
      }
    },
    toggleChecked(data, fields, ID) {
      if (data || data === 0) {
        const obj = {
          data,
          fields,
          ID,
        }
        this.$http.post('/promotion/group_update_field', obj)
      }
    },
    submitShipping() {
      this.itemsTrans = []
      const Checked = this.itemsTrans2.filter(item => item.checked)
      Checked.forEach(ele => {
        const Obj = {
          // eslint-disable-next-line no-underscore-dangle
          id_delivery: ele._id,
          delivery_source: ele.delivery_source,
          destination: ele.destination,
          name: ele.name,
          old_service: ele.service,
          old_delivery: ele.rate && ele.rate.length > 0 ? ele.rate[0].price : 0,
          delivery: ele.rate && ele.rate.length > 0 ? ele.rate[0].price : 0,
          service: ele.service,
          rate: ele.rate,
        }
        this.itemsTrans.push(Obj)
      })
    },
    setData() {
      if (this.Obj.getdate) {
        const dateRange = this.Obj.getdate.split(' to ')
        if (dateRange.length === 2) {
          const startDate = dateRange[0]
          const endDate = dateRange[1]
          this.Obj.start_date = startDate
          this.Obj.end_date = endDate
        } else {
          const startDate = dateRange[0]
          this.Obj.start_date = startDate
          this.Obj.end_date = startDate
        }
      } else {
        console.log('Please select a date range.')
      }
    },
    EditRow(rate, index) {
      this.items = []
      rate.forEach(e => {
        const Obj = {
          min: e.min,
          old_price: e.price,
          price: e.price,
        }
        this.items.push(Obj)
      })

      this.items.sort((a, b) => a.min - b.min)
      this.editindex = index
      // this.itemsTrans = res
      // console.log(this.itemsTrans2)
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },

    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
